import { styled } from '@mui/material/styles';
import { ChevronRightSharp } from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getPagePath, getRouterPath } from '../../../helpers/path';
import staticPages from '../../../pages/static/staticPages';
import WidgetAnimation from './WidgetAnimation';

const PREFIX = 'HomePageWidgetThumbnails';

const classes = {
    wrapper: `${PREFIX}-wrapper`,
    gridItem: `${PREFIX}-gridItem`,
    content: `${PREFIX}-content`,
    icon: `${PREFIX}-icon`,
};

const Root = styled('div')(({ theme, linkColor }) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill,minmax(250px, 1fr))',
    gridAutoRows: 'minmax(min-content, max-content)',
    gridGap: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
        gridTemplateColumns: 'repeat(1, 1fr)',
    },
    '& a': {
        color: linkColor || theme.palette.primary.main,
    },

    [`& .${classes.gridItem}`]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        background: theme.palette.grey[100],
        border: `1px solid ${theme.palette.divider}`,
        padding: theme.spacing(2),
        height: '100%',
        justifySelf: 'stretch',
        '& h4': {
            fontWeight: 600,
        },
        '& p': {
            color: theme.palette.grey[600],
        },
        '& a': {
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            fontWeight: 600,
        },
    },

    [`& .${classes.content}`]: {
        flex: 1,
    },

    [`& .${classes.icon}`]: {
        height: '1.5rem',
        marginBottom: theme.spacing(1.5),
    },
}));

const getPath = (item, i18n) => {
    const pages = useMemo(() => staticPages(i18n), [i18n]);
    if (item.to.indexOf('route:') === 0) {
        return getRouterPath(i18n, item.to.substr(6), i18n.language, true);
    }
    if (item.to.indexOf('key:') === 0) {
        return getPagePath(pages, item.to.substr(4));
    }
    return item.to;
};

function HomePageThumbnail({ item }) {
    const { t, i18n } = useTranslation();
    return (
        <div className={classes.gridItem}>
            {item.iconSrc && (
                <img
                    src={item.iconSrc}
                    alt={item.title}
                    className={classes.icon}
                />
            )}
            <div className={classes.content}>
                <h4>{item.title}</h4>
                <p>{item.description}</p>
            </div>
            {item.href && (
                <a href={item.href} role="menuitem" target="_blank" rel="noopener noreferrer">
                    {t('home.widgets.read_more')}
                    {' '}
                    <ChevronRightSharp />
                </a>
            )}
            {item.to && (
                <Link to={getPath(item, i18n)} role="menuitem">
                    {t('home.widgets.read_more')}
                    {' '}
                    <ChevronRightSharp />
                </Link>
            )}
        </div>
    );
}

function HomePageWidgetThumbnails({ items, linkColor, animation }) {
    if (!items) {
        return null;
    }
    return (
        <Root linkColor={linkColor}>
            <WidgetAnimation animation={animation} duration={300} delay={50} fraction={0.1} triggerOnce>
                {items.map(item => (
                    <HomePageThumbnail
                        item={item}
                        key={item.title}
                    />
                ))}
            </WidgetAnimation>
        </Root>
    );
}

function customPropType(props, propName, componentName) {
    if (!props.href && !props.to) {
        return new Error(`One of props 'href' or 'to' was not specified in '${componentName}'.`);
    }
    return true;
}

HomePageWidgetThumbnails.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        href: customPropType,
        to: customPropType,
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        imgSrc: PropTypes.string.isRequired,
    })).isRequired,
    linkColor: PropTypes.string,
    animation: PropTypes.string,
};

export default HomePageWidgetThumbnails;
